.status-box {
  display: inline-block;
  border-radius: 5px;
  
}

.project-status {
  font-size: 1.0em;
  font-weight: 700;
  color: black;
  margin: 10px;
}

.deprecated {
  background-color: rgb(255, 0, 0);
}

.inProgress {
  background-color: rgb(239, 243, 17);
}

.complete {
  background-color: rgb(0, 255, 17);
}

.completeMaintenance {
  background-color: rgb(0, 83, 22);
}

.completeUpdate {
  background-color: rgb(71, 255, 175);
}