body {
  background-color: #323232;
  background-attachment: fixed;
  background-image: url('styleimages/GenBackground.png');
  background-size: cover;
  background-position: center;
  font-family: 'Source Sans Pro', sans-serif;
  color: #f2f2f2; 
}

.under-construction-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  margin-top: 20px;
  margin-left: 50px;
  margin-right: 50px;
  border-radius: 4px;
}

.under-construction-tag span {
  margin-right: 5px;
}

.page-container {
  justify-content: center;
  margin: 0 auto;
  max-width: 900px;
}

.heading-container {
  background-color: #85414100;
  text-shadow: 2px 2px #3d3d3d;
  font-weight: 600;
}

.heading-left{
  text-align: left;
  margin: 10px;
}

.heading-large {
  font-size: 50px;
}

.heading-medium {
  font-weight: 500;
  font-size: 35px;
}

.heading-small {
  font-weight: 500;
  font-size: 21px;
}

.about-container {
  display: flex;
  justify-content: center;
}

.about-box {
  background-color: #26262677;
  border-radius: 10px;
  margin: 9px;
  width: 100%;
}

.about-box img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  padding: 20px;
  margin: 0 auto;
  display: block;
}

.about-box p {
  padding-left: 10px;
}

.sticky-container {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding-top: 1px;
  padding-bottom: 10px;
  z-index: 100;

  background-image: url('styleimages/GenBackground.png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.categories {
  position: relative;
  display: flex;
  align-items: center;
}

.category {
  margin: 0 10px;
  cursor: pointer;
  position: relative;
}

.category:hover {
  color: #ffffff;
}

.slider {
  position: absolute;
  bottom: -5px;
  left: 10px;
  height: 2px;
  width: 80px;
  background-color: rgb(255, 255, 255);
  transition: all 0.3s;
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.grid-item {
  margin: 15px;
  background-color: #26262677;
  border-radius: 7px;
  padding: 0;
  width: 340px;
  height: 400px;
  text-align: left; 
  position: relative;
}

.grid-item h3,
.grid-item p {
  padding-left: 15px;
}

.grid-item img {
  width: 100%;
  height: 200px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  object-fit: cover;
}

.button-container {
  position: absolute;
  bottom: 12px;
  left: 3px;
  right: 0;
}

.button-container button,
.button-container a {
  display: inline-block;
  text-decoration: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 15px;
  padding: 10px 15px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  color: #fff;
  margin: 0 10px;
  border: none;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.button-container button:hover,
.button-container a:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

@media screen and (min-width: 900px) {
  .grid-container {
    justify-items: center;
    max-width: 900px;
    margin: 0px auto 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  .ssh2{
    text-align: left;
  }
}

@media screen and (min-width: 500px) {
  .grid-item {
    width: 400px;
  }
}


