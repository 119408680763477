.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #262626;
  color: #ffffff;
  padding: 20px;
  width: 600px;
  max-width: 90vw;
  overflow-y: auto;
  max-height: 80vh;
  border-radius: 10px;
  position: relative;
}

.modal-content img {
  max-width: 100%;
  border-radius: 7px;
  height: auto;
  display: block;
  margin: 10px 0;
}

